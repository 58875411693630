import styled from 'styled-components';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import Logout from '../../containers/Logout';

const { Header } = Layout;

type TopMenuProps = {
    setOpenMenu: (open: boolean) => void;
    open: boolean;
};

const LogoutContainer = styled.div`
    margin: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        background: transparent;
        color: white;
    }
`;

const TopMenu: React.FC<TopMenuProps> = ({ setOpenMenu, open }) => (
    <>
        <Header className="site-layout-background" style={{ padding: 0 }}>
            {open ? (
                <MenuFoldOutlined className="trigger" onClick={() => setOpenMenu(false)} />
            ) : (
                <MenuUnfoldOutlined className="trigger" onClick={() => setOpenMenu(true)} />
            )}
            <LogoutContainer>
                <Logout />
            </LogoutContainer>
        </Header>
    </>
);

export default TopMenu;
