/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCancelMembershipMutation } from '../services/customer';
import { createNotification } from '../utils/fetchWithNotification';
import useCreateNote from './useCreateNote';

const useCancelMembership = () => {
    const [cancel, { isLoading, error }] = useCancelMembershipMutation();
    const { createNote } = useCreateNote();

    const cancelMembership = async (id: number) => {
        const result = await cancel(id);
        if (result && 'error' in result) {
            const noteMessage =
                `Cancel Error. Status ${'status' in result.error && result.error.status}` +
                ` Data: ${'data' in result.error && JSON.stringify(result.error.data)}`;

            createNotification('fail', 'Cancellation failed!');
            createNote('warning', noteMessage, id, 'The failed cancel has been recorded in customer notes');
        } else {
            createNotification('success', 'Cancellation successful!');
        }
        return result;
    };

    return {
        cancelMembership,
        isCancelling: isLoading,
        cancelError: error,
    };
};

export default useCancelMembership;
