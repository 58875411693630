import { CheckSquareOutlined, HourglassOutlined, AlertOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { OrderStatus } from '../../types';

type OrderTagProps = {
    title: OrderStatus;
    withIcon?: boolean;
};

const OrderTag: React.FC<OrderTagProps> = ({ title, withIcon = false }) => {
    let color;
    let icon;
    switch (title.toLowerCase()) {
        case 'active':
            color = 'green';
            icon = <CheckSquareOutlined />;
            break;
        case 'full':
            color = 'green';
            icon = <CheckSquareOutlined />;
            break;
        case 'trial':
            color = 'orange';
            icon = <HourglassOutlined />;
            break;
        case 'not joined':
            color = 'grey';
            icon = <AlertOutlined />;
            break;
        case 'canceled':
            color = 'purple';
            icon = <AlertOutlined />;
            break;
        case 'expired':
            color = 'red';
            icon = <AlertOutlined />;
            break;
        default:
            color = 'grey';
            icon = null;
            break;
    }

    return (
        <Tag icon={withIcon && icon} color={color} style={{ minWidth: '60px', margin: '0.5rem', textAlign: 'center' }}>
            {title || 'NO STATUS'}
        </Tag>
    );
};

export default OrderTag;
