/* eslint-disable prettier/prettier */
import { Descriptions } from 'antd';
import HoverInfo from '../../components/ui/HoverInfo';

import { CustomerDetail } from '../../types';
import { parseUrl } from '../../utils/helpers';
import ExtraDetails from './ExtraDetails';

export type CustomerInfoExtrasProps = {
    info: CustomerDetail;
};

const CustomerInfoExtras: React.FC<CustomerInfoExtrasProps> = ({ info }) => (
    <ExtraDetails mt="1rem">
        <Descriptions size="middle" title="" bordered column={{ md: 2, sm: 2, xs: 1 }}>
            <Descriptions.Item label="Customer ID">{info.id}</Descriptions.Item>
            <Descriptions.Item label="Customer IP">{info.ipAddress}</Descriptions.Item>
            <Descriptions.Item label="Phone Number">{info.phoneNumber}</Descriptions.Item>
            <Descriptions.Item label="Biller Member ID">{info.billerMemberId}</Descriptions.Item>
            <Descriptions.Item label="Affiliate Account">{info.affiliateTracker}</Descriptions.Item>
            <Descriptions.Item label="Tracker">{info.affiliateTracker}</Descriptions.Item>
            <Descriptions.Item label="Mid">{info.mid || ''}</Descriptions.Item>
            <Descriptions.Item label="Members URL">
                <HoverInfo vals={[info.membersUrl]}>
                    <a href={info.membersUrl} rel="noreferrer noopener" target="_blank">
                        {parseUrl(info.membersUrl)}
                    </a>
                </HoverInfo>
            </Descriptions.Item>
        </Descriptions>
    </ExtraDetails>
);

export default CustomerInfoExtras;
