import { Table, Input, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import type { ChangeEvent } from 'react';
import TableCell from '../../components/ui/TableCell';
import HoverInfo from '../../components/ui/HoverInfo';
import { DarkTag } from '../../components/ui/Tags';
import { getDateTimeString, getCurrencyWithDecimals } from '../../utils/helpers';
import MemberTag from '../../components/ui/MemberStatusTag';
import OrderTag from '../../components/ui/OrderTags';
import type { CustomerRow } from '../../hooks/useCustomers';
import TableWrapper from '../../components/ui/TableWrapper';

const { Text } = Typography;

const { Search } = Input;

const SearchBox = styled.div`
    display: flex;
    padding-bottom: 2rem;
    justify-content: center;
`;

type CustomersTableProps = {
    onSearch: (val: string) => void;
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    changePage: (newPage: number, lastPage: number) => void;
    changePageSize: (updatedPage: number, size: number) => void;
    searchResultsLoading: boolean;
    searchSummaryLoading: boolean;
    inputValue: string;
    totalElements: number;
    data: CustomerRow[];
    currentPage: number;
    pageSize: number;
};

const CustomersTable: React.FC<CustomersTableProps> = ({
    onSearch,
    onInputChange,
    changePage,
    changePageSize,
    searchResultsLoading,
    searchSummaryLoading,
    inputValue,
    totalElements,
    data,
    currentPage,
    pageSize,
}) => {
    const searchRef = useRef<HTMLDivElement>(null);

    // FOCUS INPUT ON LOAD
    useEffect(() => {
        if (!searchRef.current) return;
        searchRef.current.querySelector('input')!.focus();
    }, [searchRef]);

    return (
        <TableWrapper>
            <SearchBox ref={searchRef}>
                <Search
                    data-testid="searchbox"
                    placeholder="Search for customer"
                    allowClear
                    enterButton="Search"
                    type="search"
                    size="large"
                    onSearch={onSearch}
                    onChange={onInputChange}
                    style={{ maxWidth: 400 }}
                    loading={searchResultsLoading || searchSummaryLoading}
                    value={inputValue}
                />
            </SearchBox>
            <Table
                dataSource={data}
                loading={searchResultsLoading}
                scroll={{ x: true }}
                rowKey="id"
                pagination={{
                    pageSize,
                    total: totalElements,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalElements} items`,
                    onChange: changePage,
                    showSizeChanger: true,
                    onShowSizeChange: changePageSize,
                    current: currentPage,
                }}
            >
                <Table.Column
                    align="center"
                    width={150}
                    title="Account"
                    dataIndex="account"
                    key="account"
                    render={(_, record: CustomerRow) => (
                        <Link to={`/customers/${record.id}`}>
                            <DarkTag>{record.id}</DarkTag>
                        </Link>
                    )}
                />
                <Table.Column
                    align="center"
                    width={200}
                    title="Customer"
                    key="customer"
                    render={(_, record: CustomerRow) => `${record.firstName} ${record.lastName}`}
                />
                <Table.Column align="center" width={200} title="Email" dataIndex="email" key="email" />
                <Table.Column
                    align="center"
                    title="Offer"
                    dataIndex="offer"
                    key="offer"
                    render={(_, record: CustomerRow) => (
                        <TableCell loading={searchSummaryLoading}>
                            {record.offerName && (
                                <HoverInfo center vals={[`Advertiser: ${record.advertiserName}`]}>
                                    {record.offerName}
                                </HoverInfo>
                            )}
                        </TableCell>
                    )}
                />
                <Table.Column
                    align="center"
                    width={200}
                    title="Descriptor"
                    key="descriptor"
                    render={(_, record: CustomerRow) => (
                        <TableCell loading={searchSummaryLoading}>
                            <Space direction="vertical" size={2}>
                                {!record.descriptorDomains && <Text type="secondary">No descriptor</Text>}
                                {record.descriptorDomains &&
                                    record.descriptorDomains.map((domain, i) => {
                                        if (i > 1) return null;
                                        return <Text key={`descriptor${i}`}>{domain}</Text>;
                                    })}
                            </Space>
                        </TableCell>
                    )}
                />
                <Table.Column
                    align="center"
                    title="Country"
                    key="country"
                    render={(_, record: CustomerRow) => (
                        <TableCell loading={searchSummaryLoading}>{record.countryName}</TableCell>
                    )}
                />
                <Table.Column
                    align="center"
                    title="Membership"
                    key="membershipStatus"
                    render={(_, record: CustomerRow) => (
                        <TableCell loading={searchSummaryLoading}>
                            {record.membershipStatus && <MemberTag title={record.membershipStatus} />}
                        </TableCell>
                    )}
                />
                <Table.Column
                    align="center"
                    title="Recent Orders"
                    key="orderStatus"
                    render={(_, record: CustomerRow) => (
                        <TableCell loading={searchSummaryLoading}>
                            {record.orderStatuses &&
                                record.orderStatuses.map((status, i) => {
                                    if (i > 1) return null;
                                    return <OrderTag key={`order${i}`} title={status} />;
                                })}
                        </TableCell>
                    )}
                />
                <Table.Column
                    align="center"
                    title="Amount Spent"
                    key="amountSpent"
                    render={(_, record: CustomerRow) => (
                        <TableCell loading={searchSummaryLoading}>
                            {getCurrencyWithDecimals('USD', record.amountSpent || 0)}
                        </TableCell>
                    )}
                />
                <Table.Column
                    align="center"
                    title="Start Date"
                    key="transactionDate"
                    render={(_, record: CustomerRow) => (
                        <TableCell loading={searchSummaryLoading}>
                            {getDateTimeString(record.transactionDate)}
                        </TableCell>
                    )}
                />
            </Table>
        </TableWrapper>
    );
};

export default CustomersTable;
