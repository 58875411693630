import { Tag } from 'antd';
import styled from 'styled-components';

// eslint-disable-next-line prettier/prettier
export const GreenTag = styled(Tag)`
    color: white;
    background: var(--green);
    min-width: 80px;
    text-align: center;

    :hover {
        background: var(--green);
    }
`;

export const DarkTag = styled(Tag)`
    color: white;
    background: #0e574f;
    min-width: 80px;
    text-align: center;

    :hover {
        background: var(--green);
    }
`;

export const RedTag = styled(Tag)`
    color: white;
    background: #0e574f;
    width: 80px;

    :hover {
        background: var(--green);
    }
`;
