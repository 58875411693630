import styled from 'styled-components';
import { Button } from 'antd';

export type ButtonTypeNames = 'ghost' | 'danger' | 'secondary' | 'primary';

export const SecondaryButton = styled(Button)`
    background-color: var(--dark);
    color: white;
`;

export const DangerButton = styled(Button)`
    background-color: var(--bg-red);
    color: var(--red);
    border-color: var(--mid-red);

    :hover {
        color: white;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
        border-color: var(--mid-red);
        background: #fa8c86;
    }
    :focus {
        background-color: var(--bg-red);
        color: var(--red);
        border-color: var(--mid-red);
    }
`;

export const GhostButton = styled(Button)`
    color: var(--green);
    border-color: var(--green);
    background: #ffffff;
    text-shadow: none;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

    :hover {
        color: white;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
        color: #fff;
        border-color: var(--green);
        background: var(--green);
    }
`;
