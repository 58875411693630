import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { googleLogout } from '@react-oauth/google';
import { logout } from '../store/actions';

const Logout: React.FC = () => {
    const dispatch = useDispatch();

    const handleLogOut = () => {
        googleLogout();
        dispatch(logout());
    };

    return <Button onClick={handleLogOut}>Logout</Button>;
};

export default Logout;
