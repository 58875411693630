/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCreateCustomerNoteMutation } from '../services/customer';
import { createNotification } from '../utils/fetchWithNotification';

const useCreateNote = () => {
    const [createNote, { isLoading, isError, error }] = useCreateCustomerNoteMutation();

    const createNoteWithNotification = async (
        type: 'success' | 'fail' | 'warning',
        note: string,
        id: number,
        notificationText?: string
    ) => {
        const result = await createNote({ customerId: id, text: note || 'No note created' });
        if (result && 'error' in result) createNotification('fail', 'Failed to create note');
        if (notificationText) createNotification(type, notificationText);
        else createNotification('success', 'Customer note created');
    };

    return {
        createNote: createNoteWithNotification,
        isCreatingNote: isLoading,
        isCreateNoteError: isError,
        newNoteError: error,
    };
};

export default useCreateNote;
