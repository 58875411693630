import { Descriptions } from 'antd';
import { Rebilling } from '../../types';
import { getDateTimeString } from '../../utils/helpers';
import { Section } from './customerDetail.styled';

type RebillProps = {
    data: Rebilling;
};

const Rebill: React.FC<RebillProps> = ({ data }) => {
    return (
        <Section as="section">
            <Descriptions size="middle" title="Rebill" bordered column={{ md: 2, sm: 2, xs: 1 }}>
                <Descriptions.Item label="Status">{data.status}</Descriptions.Item>
                <Descriptions.Item label="Set Up Date">{getDateTimeString(data.dateAdded)}</Descriptions.Item>
                <Descriptions.Item label="Next Due Date">{getDateTimeString(data.dateNextAttempt)}</Descriptions.Item>
                <Descriptions.Item label="Next Amount">{data.recurringAmount}</Descriptions.Item>
                <Descriptions.Item label="Regular Amount">{data.recurringOriginalAmount}</Descriptions.Item>
                <Descriptions.Item label="Schedule">{`every ${data.recurringPeriodDays} days`}</Descriptions.Item>
                <Descriptions.Item label="Failed checkpoints">{data.attempts}</Descriptions.Item>
            </Descriptions>
        </Section>
    );
};

export default Rebill;
