import styled from 'styled-components';

const TableWrapper = styled.div`
    .ant-table table {
        border-spacing: 0 10px;
        background: var(--canvas);

        .ant-table-tbody {
            background: white;
        }
        tr {
            box-sizing: border-box;
            box-shadow: 4px 3px 2px 0 rgb(40 139 94 / 10%);
            height: 65px;
            min-height: 65px !important;
            position: relative;

            td {
                vertical-align: middle;
            }
            td span.indent-level-1 {
                display: none;
            }

            td button.ant-table-row-expand-icon {
                background: var(--dark);
                color: white;

                @media (max-width: 1350px) {
                    position: absolute;
                    right: -10%;
                }
            }
        }

        tr.ant-table-row-level-1 {
            background: #01182b0a;

            ::after {
                content: '';
                position: absolute;
                background: var(--dark);
                left: 0;
                width: 5px;
                height: 65px;
                right: 0;
            }
        }
    }
`;

export default TableWrapper;
