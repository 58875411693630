import { Drawer } from 'antd';
import { ReactNode, useState, useEffect } from 'react';
import styled from 'styled-components';

type DrawerProps = {
    onClose: () => void;
    visible: boolean;
    children: ReactNode;
};

const Wrapper = styled.div`
    .ant-drawer .ant-drawer-content-wrapper {
        width: 10%;
    }
`;

const SideDrawer: React.FC<DrawerProps> = ({ onClose, visible, children }) => {
    const windowSize = window.matchMedia('(max-width: 600px)');
    const [isMobile, setIsMobile] = useState<boolean>(windowSize.matches);

    useEffect(() => {
        function changeWindowSize() {
            setIsMobile(windowSize.matches);
        }

        window.addEventListener('resize', changeWindowSize);

        return () => {
            window.removeEventListener('resize', changeWindowSize);
        };
    }, [windowSize]);

    return (
        <Wrapper>
            <Drawer
                width={isMobile ? '80%' : 750}
                className="crm_drawer"
                title="Customer Timeline"
                placement="right"
                closable={false}
                onClose={onClose}
                open={visible}
                headerStyle={{ justifyContent: 'center' }}
            >
                {children}
            </Drawer>
        </Wrapper>
    );
};

export default SideDrawer;
