import styled from 'styled-components';

const Circle = styled.div`
    height: 100px;
    width: 100px;
    position: relative;
`;

const InnerCircle = styled.div`
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    height: 80px;
    width: 80px;
    margin: -40px 0 0 -40px;
    background: white;
    border-radius: 100%;
`;

const Number = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: 18px;
    font-weight: 500;
    color: var(--dark); ;
`;

const ClippedBar = styled.div<{ rotate?: 'true' }>`
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--green);
    -webkit-border-radius: 100%;
    clip: rect(0px, 100px, 100px, 50px);

    transform: ${({ rotate }) => (rotate === 'true' ? 'rotate(180deg)' : 'initial')};
    z-index: ${({ rotate }) => (rotate === 'true' ? 3 : 'initial')};
`;

const Progress = styled.div<{ position: 'left' | 'right'; timeLength: number }>`
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 100%;
    clip: rect(0px, 50px, 100px, 0px);
    background: var(--dark);

    z-index: ${({ position }) => (position === 'left' ? 1 : 'initial')};
    animation: ${({ timeLength }) => `rotate ${timeLength}s linear both`};
    animation-delay: ${({ position, timeLength }) => (position === 'left' ? 'initial' : `${timeLength}s`)};

    @keyframes rotate {
        100% {
            transform: rotate(180deg);
        }
    }
`;

type CircleProgressProps = {
    number: string | number;
    seconds: number;
};

const CircleProgress: React.FC<CircleProgressProps> = ({ number, seconds }) => {
    return (
        <Circle>
            <InnerCircle />
            <Number>{number}</Number>
            <div className="circle">
                <ClippedBar>
                    <Progress position="left" timeLength={seconds / 2} />
                </ClippedBar>
                <ClippedBar rotate="true">
                    <Progress position="right" timeLength={seconds / 2} />
                </ClippedBar>
            </div>
        </Circle>
    );
};

export default CircleProgress;
