import React from 'react';
import { Pagination } from 'antd';
import { useParams } from 'react-router';
import styled from 'styled-components';
import useCustomerNotes from '../hooks/useCustomerNotes';
import { Flex } from '../components/ui/Flex.styled';
import NotesTimeline from '../features/customerDetail/timelines/NotesTimelines';
import PopConfirmWithNote from '../features/notes/PopConfirmWithNote';
import useCreateNote from '../hooks/useCreateNote';

const AddNote = styled.div`
    padding: 4rem;
    padding-top: 0;
    display: flex;
    justify-content: center;
`;

const CustomerNotes: React.FC = () => {
    const { createNote, isCreatingNote } = useCreateNote();
    const { id } = useParams<'id'>();
    const { notes, totalNotes, pageSize, pageChange, pageSizeChange, isLoading, error, currentPage } =
        useCustomerNotes(10);

    const onCreate = async (values: any) => {
        const submission = values as { note: string };
        await createNote('success', submission.note, +id!);
    };

    return (
        <>
            <AddNote>
                <PopConfirmWithNote btnType="ghost" isLoading={isCreatingNote} onCreate={onCreate} />
            </AddNote>
            <NotesTimeline isLoading={isLoading} error={!!error} notes={notes} />
            {notes && notes.length > 0 && (
                <Flex>
                    <Pagination
                        pageSize={pageSize}
                        current={currentPage}
                        style={{ marginTop: '2.5rem' }}
                        total={totalNotes}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${totalNotes} items`}
                        onChange={pageChange}
                        showSizeChanger
                        onShowSizeChange={pageSizeChange}
                    />
                </Flex>
            )}
        </>
    );
};

export default CustomerNotes;
