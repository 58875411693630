import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';

const { TextArea } = Input;

interface Values {
    title: string;
    description: string;
    modifier: string;
}

interface CollectionCreateFormProps {
    visible: boolean;
    isLoading: boolean;
    formTitle?: string;
    onCreate: (values: Values) => void;
    onCancel: () => void;
    submitText?: string;
    exitText?: string;
    extraContent?: React.ReactNode;
}

const CreateNotesForm: React.FC<CollectionCreateFormProps> = ({
    visible,
    onCreate,
    onCancel,
    isLoading,
    formTitle,
    submitText,
    exitText,
    extraContent,
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        return () => form.resetFields();
    }, [visible, form]);

    return (
        <Modal
            destroyOnClose
            open={visible}
            title={formTitle || 'Create a new customer note'}
            okText={submitText || 'Create'}
            cancelText={exitText || 'Cancel'}
            onCancel={onCancel}
            confirmLoading={isLoading}
            onOk={() => {
                form.validateFields()
                    .then((values) => onCreate(values))
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                return true;
            }}
        >
            {extraContent}
            <Form form={form} layout="vertical" name="notes_form">
                <Form.Item name="note" label="Add a note">
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateNotesForm;
