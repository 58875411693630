import styled from 'styled-components';

const Paper = styled.div`
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 6%) 3px 3px 2px 0px;
`;

export default Paper;
