import { Timeline } from 'antd';
import { Payment } from '../../../types';
import TransactionTypeTag from '../../../components/ui/TransactionTypeTags';
import TransactionStatusTag from '../../../components/ui/TransactionStatusTag';
import { getCurrencyWithDecimals, getDateTimeString } from '../../../utils/helpers';

type TransactionTimeLineProps = {
    transactions: Payment[];
};

const TransactionsTimeline: React.FC<TransactionTimeLineProps> = ({ transactions }) => (
    <Timeline mode="right">
        {transactions.map((payment) => {
            return (
                <Timeline.Item
                    key={payment.id}
                    label={
                        <div>
                            <p>
                                <TransactionStatusTag status={payment.status} />
                            </p>
                            <p>{getDateTimeString(payment.date)}</p>
                        </div>
                    }
                >
                    <p>
                        <TransactionTypeTag title={payment.type} />
                    </p>
                    <p>Amount: {getCurrencyWithDecimals(payment.currency, payment.amount)}</p>
                </Timeline.Item>
            );
        })}
    </Timeline>
);

export default TransactionsTimeline;
