import { Route, Routes } from 'react-router-dom';
import CustomerDetails from './CustomerDetails';
import Customers from './CustomersList';
import PageNotFound from './PageNotFound';

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Customers />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customers/:id" element={<CustomerDetails />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default AppRoutes;
