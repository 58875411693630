import { Tag } from 'antd';
import { TransactionStatus } from '../../types';

type TransactionStatusTagProps = {
    status: TransactionStatus;
};

const TransactionStatusTag: React.FC<TransactionStatusTagProps> = ({ status }) => {
    if (status === 'SUCCEED') return <Tag color="green">{status}</Tag>;
    if (status === 'FAILED') return <Tag color="red">{status}</Tag>;
    if (status === 'REFUNDED') return <Tag color="purple">{status}</Tag>;
    return <Tag color="purple">{status}</Tag>;
};

export default TransactionStatusTag;
