import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from '../store';
import { logout, refreshTokens } from '../store/actions';
import type { ErrorResponse } from '../types';
import type { AuthTokens } from './auth/types';

// THE BASE QUERY WHICH ATTACHES AUTH HEADER
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_SERVICES_URI,
    prepareHeaders: (headers, { getState }) => {
        const { accessToken } = (getState() as RootState).auth;
        // THIS ATTACHES THE TOKEN TO EVERY REQUEST
        if (accessToken) headers.set('authorization', `Bearer ${accessToken}`);
        return headers;
    },
}) as BaseQueryFn<string | FetchArgs, unknown, { status: number; data?: ErrorResponse }>;

// CUSTOM BASE QUERY WHICH WILL ATTEMPT TO FETCH NEW TOKENS IF ACCESS TOKEN EXPIRED
export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    { status: number; data?: ErrorResponse }
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    const state = api.getState() as RootState;
    const existingRefreshToken = state.auth.refreshToken;
    const is401Error = result.error && (result.error.status === 401 || result.error.data?.status === 401);

    // HANDLE 401 ERROR
    if (is401Error && existingRefreshToken) {
        const er = result.error!;
        try {
            if (er.data && !er.data.error.includes('Access token expired'))
                throw new Error(er.data.error || 'Error is not to do with an expired token');

            // TOKEN EXPIRED. TRY GET NEW TOKEN
            const refreshResult = await baseQuery(
                {
                    url: '/authentication-service/refreshtoken',
                    method: 'POST',
                    body: {
                        refreshToken: existingRefreshToken,
                    },
                },
                api,
                extraOptions
            );

            const response = refreshResult.data as AuthTokens;

            if (refreshResult.error || !response.accessToken) throw new Error('Failed to fetch new token');

            // UPDATE TOKENS IN STATE
            api.dispatch(
                refreshTokens({
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                    expires_in: response.expires_in,
                })
            );

            // RETRY INITIAL QUERY
            result = await baseQuery(args, api, extraOptions);
        } catch (e) {
            // 401 ERROR AND LOGGING OUT
            api.dispatch(logout());
        }
    }
    return result;
};
