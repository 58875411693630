import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Modal } from 'antd';
import styled from 'styled-components';
import CircleProgress from '../../components/ui/CircleProgress';

type SessionTimeoutProps = {
    timeout: number;
    promptTimeout: number;
    onIdle: () => void | unknown;
};

const StyledModal = styled(Modal)`
    .ant-btn-default {
        display: none;
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const SessionTimeout: React.FC<SessionTimeoutProps> = ({ timeout, promptTimeout, onIdle }) => {
    const [remaining, setRemaining] = useState(timeout);
    const [prompt, setPrompt] = useState(false);

    const onPrompt = () => {
        // Fire a Modal Prompt
        setPrompt(true);
    };

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { reset, getRemainingTime, isIdle } = useIdleTimer({
        onPrompt,
        onIdle,
        timeout,
        promptTimeout,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
        ],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        crossTab: true,
        syncTimers: 0,
    });

    const handleReset = () => {
        setPrompt(false);
        setRemaining(timeout);
        reset();
    };

    const isIdl = isIdle();

    useEffect(() => {
        if (isIdl) return;
        setRemaining(getRemainingTime());
        const countdown = setInterval(() => {
            setRemaining(getRemainingTime());
        }, 1000);

        return () => clearInterval(countdown);
    }, [isIdl, getRemainingTime]);

    const remainingSeconds = Math.floor(remaining / 1000);

    if (!prompt || !remainingSeconds) return null;

    return (
        <StyledModal
            title="Are you still there?"
            okText="Stay Logged In"
            open={prompt}
            onOk={handleReset}
            onCancel={handleReset}
        >
            <Flex>
                <p>Your session is about to expire in ...</p>
                <CircleProgress seconds={promptTimeout / 1000} number={Math.floor(remaining / 1000)} />
            </Flex>
        </StyledModal>
    );
};

export default SessionTimeout;
