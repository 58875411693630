import { Button, Descriptions } from 'antd';
import { CustomerDetail } from '../../types';
import { turnUnknownValuesIntoString } from '../../utils/helpers';
import Copyable from '../../components/ui/Copyable';
import HoverInfo from '../../components/ui/HoverInfo';

import { Section } from './customerDetail.styled';
import CustomerInfoExtras from './CustomerInfoExtras';

export type CustomerInfoProps = {
    showTimeline: () => void;
    updateEmail?: (text: string) => void;
    info: CustomerDetail;
};

const CustomerInfo: React.FC<CustomerInfoProps> = ({ showTimeline, info, updateEmail }) => {
    return (
        <Section as="section" flex column>
            <Descriptions
                size="middle"
                title="Customer Summary"
                bordered
                column={{ lg: 3, md: 2, sm: 2, xs: 1 }}
                extra={
                    <>
                        <Button type="primary" onClick={showTimeline}>
                            Timeline & Notes
                        </Button>
                    </>
                }
            >
                <Descriptions.Item label="First Name">{info.firstName}</Descriptions.Item>
                <Descriptions.Item label="Username">
                    <Copyable hoverMsg="Copy username" copySuccess="Username copied.">
                        {info.username}
                    </Copyable>
                </Descriptions.Item>
                <Descriptions.Item label="Advertiser">{info.advertiserName}</Descriptions.Item>

                <Descriptions.Item label="Last Name">{info.lastName}</Descriptions.Item>

                <Descriptions.Item label="Password">
                    <Copyable hoverMsg="Copy password" copySuccess="Password copied.">
                        {info.password}
                    </Copyable>
                </Descriptions.Item>

                <Descriptions.Item label="Biller Used">{info.billerName}</Descriptions.Item>

                <Descriptions.Item label="Email Address">
                    <Copyable editable hoverMsg="Copy email" copySuccess="Email address copied." onEdit={updateEmail}>
                        {info.email}
                    </Copyable>
                </Descriptions.Item>

                <Descriptions.Item label="Descriptor">{info.descriptorDomain}</Descriptions.Item>

                <Descriptions.Item label="Device">{info.device}</Descriptions.Item>

                <Descriptions.Item label="Address">
                    {info.address && (
                        <HoverInfo vals={[info.address.city, info.address.street]}>
                            {turnUnknownValuesIntoString(['Zip', info.address.zip, info.address.country])}
                        </HoverInfo>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Offer">{info.offerName}</Descriptions.Item>
                <Descriptions.Item label="Credit Card Bin">
                    {info.bin && <HoverInfo vals={[`The first 6 digits`]}>{info.bin}</HoverInfo>}
                </Descriptions.Item>
            </Descriptions>
            <CustomerInfoExtras info={info} />
        </Section>
    );
};

export default CustomerInfo;
