import { Alert } from 'antd';
import styled from 'styled-components';

type ErrorProps = {
    message: string;
};

const Wrapper = styled.div`
    padding-top: 1.5rem;
`;

const ErrorMessage: React.FC<ErrorProps> = ({ message }) => (
    <Wrapper>
        <Alert message={message} type="error" showIcon />
    </Wrapper>
);

export default ErrorMessage;
