import { Button, Descriptions } from 'antd';
import { CustomerDetail, MemberStatus } from '../../types';
import { getDateTimeString } from '../../utils/helpers';
import HoverInfo from '../../components/ui/HoverInfo';
import MemberTag from '../../components/ui/MemberStatusTag';
import OrderTag from '../../components/ui/OrderTags';
import { DarkTag } from '../../components/ui/Tags';
import PopConfirmWithNote from '../notes/PopConfirmWithNote';
import { Section } from './customerDetail.styled';

export type MembershipsProps = {
    data: CustomerDetail;
    showTimeline: () => void;
    cancel: (id: number, note?: string) => void;
    cancelInitiated: boolean;
    sendCancelEmailInitiated: boolean;
    memberStatus: MemberStatus;
    customerId: number;
    sendCancelEmail: (id: number, note?: string) => void;
};

const Memberships: React.FC<MembershipsProps> = ({
    data,
    showTimeline,
    cancel,
    sendCancelEmail,
    memberStatus,
    customerId,
    cancelInitiated = false,
    sendCancelEmailInitiated = false,
}) => {
    const handleCancel = (vals: any) => {
        const submission = vals as { note: string };
        cancel(customerId, submission.note);
    };

    const handleSendCancelEmail = (vals: any) => {
        const submission = vals as { note: string };
        sendCancelEmail(data.id, submission.note);
    };

    return (
        <Section as="section">
            <Descriptions
                size="middle"
                title="Membership Details"
                bordered
                column={{ md: 2, sm: 2, xs: 1 }}
                extra={
                    <>
                        {data.cancelMembershipSupported && (
                            <PopConfirmWithNote
                                isLoading={cancelInitiated}
                                onCreate={handleCancel}
                                formTitle={`Cancel membership for customer ${customerId}`}
                                btnText="Cancel Membership"
                                btnType="danger"
                                submitText="Cancel Membership"
                                exitText="Exit"
                            />
                        )}
                        {memberStatus === 'CANCELED' && (
                            <PopConfirmWithNote
                                isLoading={sendCancelEmailInitiated}
                                onCreate={handleSendCancelEmail}
                                formTitle="Resend cancel membership confirmation email"
                                btnText="Send Canceled Email"
                                btnType="ghost"
                                submitText="Resend Email"
                                exitText="Exit"
                            />
                        )}
                    </>
                }
            >
                <Descriptions.Item label="Join Package">{data.joinPackageName}</Descriptions.Item>
                <Descriptions.Item label="Join Option Used">{data.joinOptionName}</Descriptions.Item>
                <Descriptions.Item label="Transaction Date">
                    {getDateTimeString(data.transactionDate)}
                </Descriptions.Item>
                <Descriptions.Item label="Member Status">
                    <MemberTag title={memberStatus} />
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                    {data.originalMembership ? (
                        <a
                            href={`${window.location.origin}/customers/${data.originalMembership}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div style={{ width: '100px' }}>
                                <HoverInfo vals={['See original membership']}>
                                    <DarkTag>{data.membershipType}</DarkTag>
                                </HoverInfo>
                            </div>
                        </a>
                    ) : (
                        data.membershipType
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Order Status">
                    {data.statuses.map((status, i) => (
                        <OrderTag key={`order${i}`} title={status} />
                    ))}
                </Descriptions.Item>
                <Descriptions.Item label="Expiration Date">{getDateTimeString(data.expirationDate)}</Descriptions.Item>
                <Descriptions.Item label="Last Login">
                    {data.loginLogs && data.loginLogs.length > 0 && getDateTimeString(data.loginLogs[0].date)}{' '}
                    <Button onClick={showTimeline} size="small">
                        View log
                    </Button>
                </Descriptions.Item>
            </Descriptions>
        </Section>
    );
};

export default Memberships;
