import styled from 'styled-components';

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.div`
    margin-bottom: 20px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

type SectionHeaderProps = {
    title: string;
    children?: React.ReactNode;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, children }) => {
    return (
        <Flex>
            <Title>{title}</Title>
            {children}
        </Flex>
    );
};

export default SectionHeader;
