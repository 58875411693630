/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import type { CredentialResponse } from '@react-oauth/google';
import LoginForm from '../features/auth/LoginForm';
import { useGoogleLoginMutation, useLoginMutation } from '../services/auth';

const Login: React.FC = () => {
    const [login, { isLoading, error }] = useLoginMutation();
    const [googleLogin, { error: googleError }] = useGoogleLoginMutation();
    const [networkError, setNetworkError] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);

    const submitHandler = async (values: { userName: string; password: string }) => {
        setNetworkError(false);
        try {
            await login({ username: values.userName, password: values.password });
        } catch (e: any) {
            Sentry.captureEvent(e);
            setNetworkError(true);
        }
    };

    const googleFailureHandler = () => {
        Sentry.captureException('Google Login Error');
        setNetworkError(true);
    };

    const googleSuccessHandler = async (res: CredentialResponse) => {
        setNetworkError(false);
        setGoogleLoading(true);
        // HERE WE VALIDATE THE GOOGLE TOKEN WITH THE BACKEND
        await googleLogin(`${res.credential}`);
    };

    const loginError = networkError || error || googleError;

    return (
        <LoginForm
            submit={submitHandler}
            errorMessage={loginError && 'Authentication failed. Please try again'}
            loading={isLoading || (googleLoading && !loginError)}
            googleSuccess={googleSuccessHandler}
            googleError={googleFailureHandler}
        />
    );
};

export default Login;
