import { Timeline, Spin } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Note } from '../../../types';
import { getDateTimeString } from '../../../utils/helpers';
import { DarkTag, GreenTag } from '../../../components/ui/Tags';
import ErrorMessage from '../../../components/ui/ErrorMessage';
import { Flex } from '../../../components/ui/Flex.styled';

type NotesTimeLineProps = {
    isLoading: boolean;
    error: boolean;
    notes: Note[];
};

const NotesTimeline: React.FC<NotesTimeLineProps> = ({ isLoading, error, notes }) => {
    if (isLoading)
        return (
            <Flex>
                <Spin />
            </Flex>
        );

    if (error) return <ErrorMessage message="Error fetching customer notes" />;

    return (
        <>
            <Timeline mode="left">
                {notes.map((customerNote: Note) => (
                    <Timeline.Item
                        dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
                        key={`${customerNote.date.toString()}${Math.random()}`}
                        label={
                            <Flex justifyContent="flex-end">
                                <small style={{ marginRight: '0.5rem', fontSize: '12px' }}>
                                    {getDateTimeString(customerNote.date)}
                                </small>
                                {customerNote.username !== 'System' ? (
                                    <GreenTag>{customerNote.username}</GreenTag>
                                ) : (
                                    <DarkTag>{customerNote.username}</DarkTag>
                                )}
                            </Flex>
                        }
                    >
                        {`${customerNote.note.replace(/&#39;/g, "'")}`}
                    </Timeline.Item>
                ))}
            </Timeline>
        </>
    );
};

export default NotesTimeline;
