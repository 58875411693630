import { UpCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { turnUnknownValuesIntoString } from '../../utils/helpers';

type HoverInfoProps = {
    vals: unknown[];
    children: ReactNode;
    center?: boolean;
};

const FlexWrapper = styled.div<{ center: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
`;

const HoverInfo: React.FC<HoverInfoProps> = ({ vals, children, center = false }) => {
    const hoverText = turnUnknownValuesIntoString(vals);
    if (!hoverText) return <>{children}</>;
    return (
        <Tooltip title={hoverText} color="var(--dark)">
            <FlexWrapper center={center}>
                <UpCircleOutlined
                    style={{ fontSize: '10px', color: 'var(--green)', marginRight: '0.5rem', marginLeft: 0 }}
                />
                {children}
            </FlexWrapper>
        </Tooltip>
    );
};

export default HoverInfo;
