import { useParams } from 'react-router';
import { useGetCustomerNotesQuery } from '../services/customer';
import { Note } from '../types';
import usePagination from './usePagination';

type UseCustomerNotesHook = {
    notes: Note[]; // AN ARRAY OF ORDERS
    totalNotes: number; // THE TOTAL ORDERS PROVIDED TO US BY THE ENDPOINT
    totalPages: number;
    pageSize: undefined | number;
    isLoading: boolean;
    currentPage: number;
    error?: boolean;
    pageChange: (newPage: number, lastPage: number) => void;
    pageSizeChange: (updatedPage: number, size: number) => void;
};

const useCustomerNotes = (initialPageSize: number): UseCustomerNotesHook => {
    const { pageSize, changePage, changePageSize, currentPage } = usePagination(initialPageSize);

    const { id: customerId } = useParams<'id'>();

    const {
        data,
        isError,
        isLoading: notesLoading,
        isFetching: notesFetching,
    } = useGetCustomerNotesQuery({ customerId: +customerId!, pageNumber: currentPage, pageSize });

    let totalNotes = 0;
    const filteredData = data?.content || [];
    if (data && data.totalElements) totalNotes = +data.totalElements;

    return {
        notes: filteredData,
        currentPage: currentPage + 1,
        totalNotes,
        totalPages: data?.totalPages || 0,
        pageSize,
        pageChange: changePage,
        pageSizeChange: changePageSize,
        isLoading: notesLoading || notesFetching,
        error: isError,
    };
};

export default useCustomerNotes;
