import { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageHeader } from 'antd';
import styled from 'styled-components';

type PageTitleProps = {
    title: string;
    backUrl?: string;
    onBack?: () => void;
    extra?: ReactNode;
};

const Wrapper = styled.div`
    .ant-page-header {
        padding-left: 0;
    }
    .ant-page-header-back {
        background: #f0f2f5;
        padding: 0rem;
        box-shadow: 3px 5px 6px -2px rgb(0 0 0 / 30%);
        background: #0115290d;
        margin-right: 4rem;
        .ant-page-header-back-button {
            padding: 1rem !important;
        }

        :hover {
            box-shadow: 7px 8px 6px -2px rgb(0 0 0 / 30%);
        }
    }
`;

const PageTitle: React.FC<PageTitleProps> = ({ title, backUrl, onBack, extra }) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (onBack)
        return (
            <Wrapper>
                <PageHeader onBack={onBack} title={title} extra={extra} />
            </Wrapper>
        );

    let goBackDestination: any = '/';
    if (backUrl) goBackDestination = backUrl;
    else if (location.key.toLocaleLowerCase() !== 'default') goBackDestination = -1; // THIS WILL NAVIGATE BACK TO THE LAST PAGE ON THE STACK

    return (
        <Wrapper>
            <PageHeader onBack={() => navigate(goBackDestination)} title={title} extra={extra} />
        </Wrapper>
    );
};

export default PageTitle;
