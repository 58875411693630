/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useSendRefundEmailMutation, useSendCancelEmailMutation } from '../services/customer';
import { fetchWithNotification } from '../utils/fetchWithNotification';

const useSendEmail = () => {
    const [sendRefundEmail, { isLoading }] = useSendRefundEmailMutation();
    const [sendCancelEmail, { isLoading: isSendingCancel }] = useSendCancelEmailMutation();

    async function sendRefundEmailWithNotification(ids: number[], customerId: string) {
        const result = await fetchWithNotification(() => sendRefundEmail({ transactions: ids, id: customerId }), {
            success: 'Refund email confirmation successfully sent!',
            fail: 'Failed to send refund confirmation email',
        });
        return result;
    }

    async function sendCancelEmailWithNotification(id: number) {
        const result = await fetchWithNotification(() => sendCancelEmail(id), {
            success: 'Cancel email confirmation successfully sent!',
            fail: 'Failed to send cancel confirmation email',
        });
        return result;
    }

    return {
        sendRefundEmail: sendRefundEmailWithNotification,
        sendCancelEmail: sendCancelEmailWithNotification,
        isLoading: isLoading || isSendingCancel,
    };
};

export default useSendEmail;
