import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';

const { Panel } = Collapse;

type ExtraDetailsProps = {
    children: ReactNode;
    text?: string;
    mt?: string | undefined; // for setting top margin
};

const ExtraDetailsContainer = styled.div<{ marginTop: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${(props) => props.marginTop || 'auto'};
    .ant-collapse {
        border: none;

        > .ant-collapse-item {
            border-bottom: none;
        }

        .ant-collapse-content > .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

const ExtraDetails: React.FC<ExtraDetailsProps> = ({ children, text, mt = '' }) => (
    <ExtraDetailsContainer marginTop={mt}>
        <Collapse>
            <Panel header={text || 'Extra'} key="1">
                {children}
            </Panel>
        </Collapse>
    </ExtraDetailsContainer>
);

export default ExtraDetails;
