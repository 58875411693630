import { CheckCircleTwoTone, CopyOutlined } from '@ant-design/icons';
import { Popconfirm, Spin, Typography } from 'antd';
import { ReactNode, useState } from 'react';

const { Paragraph } = Typography;

type CopyableProps = {
    hoverMsg: string;
    copySuccess: string;
    editable?: boolean;
    loading?: boolean;
    onEdit?: (text: string) => void;
    children: ReactNode;
};

const Copyable: React.FC<CopyableProps> = ({ hoverMsg, copySuccess, children, editable, loading, onEdit }) => {
    const [updatedValue, setUpdatedValue] = useState('');
    const [showConfirmUpdate, setshowConfirmUpdate] = useState(false);

    const handlePopupConfirm = () => {
        if (onEdit) onEdit(updatedValue);
        setshowConfirmUpdate(false);
        setUpdatedValue('');
    };

    const handlePopupCancel = () => {
        setUpdatedValue('');
        setshowConfirmUpdate(false);
    };

    if (loading) return <Spin />;

    if (editable && onEdit)
        return (
            <Popconfirm
                title={`Update the email to: "${updatedValue}"?`}
                onConfirm={handlePopupConfirm}
                onCancel={handlePopupCancel}
                okText="Yes"
                cancelText="No"
                open={showConfirmUpdate}
            >
                <Paragraph
                    copyable={{
                        icon: [<CopyOutlined key="copy-icon" />, <CheckCircleTwoTone key="copied-icon" />],
                        tooltips: [hoverMsg, copySuccess],
                    }}
                    editable={{
                        onChange: (str) => {
                            if (str !== children) {
                                setUpdatedValue(str);
                                setshowConfirmUpdate(true);
                            }
                        },
                        // onEnd: () => setshowConfirmUpdate(true),
                    }}
                >
                    {updatedValue || children}
                </Paragraph>
            </Popconfirm>
        );

    return (
        <Paragraph
            copyable={{
                icon: [<CopyOutlined key="copy-icon" />, <CheckCircleTwoTone key="copied-icon" />],
                tooltips: [hoverMsg, copySuccess],
            }}
        >
            {children}
        </Paragraph>
    );
};

export default Copyable;
