import getSymbolFromCurrency from 'currency-symbol-map';

export function addZero(num: number): string {
    if (num > 9) return `${num}`;
    return `0${num}`;
}

export function formatDate(dateObject: Date): string {
    if (!(dateObject instanceof Date)) return '';
    return `${addZero(dateObject.getDate())}/${addZero(dateObject.getMonth() + 1)}/${dateObject.getFullYear()}`;
}

export function getDateTimeString(datestamp: Date | string): string {
    if (!datestamp) return '';
    const date = new Date(datestamp);
    if (!(date instanceof Date)) return '';
    return `${formatDate(date)} ${date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    })}`;
}

export function getDateString(datestamp: Date | string): string {
    const date = new Date(datestamp);
    if (!(date instanceof Date)) return '';
    return `${date.toDateString()}`;
}

export function getAmountInCurrency(curr: string, amount: string | number): string {
    return `${getSymbolFromCurrency(curr)}${amount}`;
}

// AMOUNT RECEIVED AS A STRING
export function addDecimalsToAmount(amount: string | number): string {
    const str = `${amount}`; // convert to string;
    if (!amount || str.includes('.')) return str; // if amount already contains a decimal, return amount
    return `${str}.00`;
}

export function getCurrencyWithDecimals(curr: string | undefined, amount: number | string | undefined): string {
    if (amount === undefined) return '';
    if (!curr) return `${amount}`;
    return getAmountInCurrency(curr, addDecimalsToAmount(amount));
}

export function turnUnknownValuesIntoString(vals: unknown[]): string {
    if (vals.length < 1) return '';
    if (vals.length === 1) return `${!vals[0] ? '' : vals[0]}`;
    return vals.filter((val) => val).join(', ');
}

export function parseUrl(url: string): string {
    if (!url) return '';
    const a = document.createElement('a');
    a.setAttribute('href', url);
    return a.hostname;
}

// TAKES THE MILLISECONDS ELAPSED SINCE THE UNIX EPOCH
export function withinExpirationDate(expirationTime: number): boolean {
    return Date.now() < expirationTime;
}

// CREATES THE MILLISECONDS ELAPSED SINCE THE UNIX EPOCH, ADDING HOURS FROM NOW
export function createUnixEpochElapsedExpireyTime(hoursFromNow: number, inPast = false): number {
    if (inPast) return new Date().getTime() - hoursFromNow * 60 * 60 * 1000;
    return new Date().getTime() + hoursFromNow * 60 * 60 * 1000;
}

export function calculateHoursUntilExpired(expiresIn: number): number {
    return expiresIn / 60 / 60 - 0.5;
}

export function minutesToMilliseconds(minutes: number): number {
    return 1000 * 60 * +minutes;
}
