import { Button } from 'antd';
import { useEffect, useState } from 'react';
import CreateNotesForm from './NotesForm';
import { ButtonTypeNames, DangerButton, GhostButton } from '../../components/ui/Buttons';

interface AddNotePopupProps {
    isLoading: boolean;
    formTitle?: string;
    onCreate: (values: any) => void;
    btnType?: ButtonTypeNames;
    btnText?: string;
    submitText?: string;
    exitText?: string;
    extraContent?: React.ReactNode;
}

const PopConfirmWithNote: React.FC<AddNotePopupProps> = ({
    isLoading,
    formTitle,
    onCreate,
    btnType = 'primary',
    btnText,
    submitText,
    exitText,
    extraContent,
}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!isLoading && visible) {
            setVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    let Btn = Button;
    if (btnType === 'danger') Btn = DangerButton;
    if (btnType === 'ghost') Btn = GhostButton;

    return (
        <>
            <Btn
                type="primary"
                data-testid="noteButton"
                style={{ marginLeft: '15px' }}
                onClick={() => {
                    setVisible(true);
                }}
            >
                {btnText || 'Create New Note'}
            </Btn>

            <CreateNotesForm
                visible={visible}
                isLoading={isLoading}
                onCreate={onCreate}
                formTitle={formTitle}
                submitText={submitText}
                exitText={exitText}
                extraContent={extraContent}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </>
    );
};

export default PopConfirmWithNote;
