/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import Paper from '../../components/ui/Paper';

export const Section = styled(Paper) <{ flex?: boolean; column?: boolean }>`
    padding: 2rem 3rem;
    margin: auto;
    margin-bottom: 3rem;
    display: ${(props) => (props.flex ? 'flex' : 'block')};
    flex-direction: ${(props) => (props.column ? 'column' : 'row')};

    .ant-descriptions,
    .ant-table-wrapper {
        width: 100%;
        .ant-table-title {
            padding-left: 0;
            padding-top: 0;
        }
    }

    .ant-descriptions-item-label {
        color: rgb(11 73 71);
        font-weight: bold;
    }

    div.ant-typography,
    .ant-typography p {
        margin-bottom: 0;
    }
`;

export const DetailsLayout = styled.div`
    max-width: 1500px;
    margin: auto;
`;
