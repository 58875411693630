import { Skeleton } from 'antd';
import styled from 'styled-components';

type TableCellProps = {
    loading: boolean;
    children: React.ReactNode;
};

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const TableCell: React.FC<TableCellProps> = ({ loading, children }) => {
    if (loading)
        return (
            <Container>
                <Skeleton.Input size="small" style={{ width: 100 }} active={loading} />
            </Container>
        );
    return <>{children}</>;
};

export default TableCell;
