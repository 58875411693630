/* eslint-disable @typescript-eslint/no-unsafe-return */
import { MiddlewareAPI, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

export const rtkQueryErrorLogger: Middleware = (_: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood
    if (isRejectedWithValue(action)) {
        const ac = action as {
            error: { message?: string };
            meta?: { arg?: { endpointName?: string }; baseQueryMeta: { request: any } };
        };

        Sentry.withScope((scope) => {
            scope.setExtra('Response Error Message', ac?.error.message || 'No error message provided');
            const erMsg = ac?.meta?.arg?.endpointName || 'App State Error';
            Sentry.captureMessage(erMsg);
        });
    }

    return next(action);
};
