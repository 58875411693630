import { Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CustomerNotes from '../../../containers/CustomerNotes';
import { LoginLog, Payment } from '../../../types';
import LoginsTimeline from './LoginsTimeline';
import TransactionsTimeline from './TransactionsTimeline';

export type Timelines = 'Transactions' | 'Notes' | 'Logins';

export type TimeLineProps = {
    transactions: Payment[];
    logins: LoginLog[];
    show?: Timelines;
};

const TimeLineWrapper = styled.div`
    width: 100%;

    .ant-timeline-item-label,
    .ant-timeline-item-content {
        padding: 1rem;
    }
`;

const Options = styled.div`
    width: 100%;
    margin-bottom: 4rem;
    border-bottom: 2px solid var(--green);
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
`;

const TimeLine: React.FC<TimeLineProps> = ({ transactions, logins, show }) => {
    const [mode, setMode] = useState<Timelines>(show || 'Notes');
    const originalMode = useRef(show);

    useEffect(() => {
        if (show && show !== originalMode.current) {
            setMode(show);
            originalMode.current = show;
        }
    }, [show]);

    const onChange = (e: RadioChangeEvent) => {
        setMode(e.target.value);
    };

    return (
        <TimeLineWrapper>
            <Options>
                <Radio.Group
                    onChange={onChange}
                    value={mode}
                    style={{
                        marginBottom: 20,
                    }}
                >
                    <Radio value="Transactions">Transactions</Radio>
                    <Radio value="Notes">Notes</Radio>
                    <Radio value="Logins">Logins</Radio>
                </Radio.Group>
            </Options>
            {mode === 'Transactions' && <TransactionsTimeline transactions={transactions} />}
            {mode === 'Notes' && <CustomerNotes />}
            {mode === 'Logins' && <LoginsTimeline logins={logins} />}
        </TimeLineWrapper>
    );
};

export default TimeLine;
