/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

export async function fetchWithNotification<T>(callback: () => Promise<T>, options: { success: string; fail: string }) {
    try {
        const result = await callback();
        if (!result || 'error' in result) throw new Error();
        notification.open({
            duration: 15,
            message: options.success,
            icon: <CheckCircleOutlined data-testid="successNotification" style={{ color: 'var(--green)' }} />,
        });
        return result;
    } catch (e) {
        notification.open({
            duration: 15,
            message: options.fail,
            icon: <CloseCircleOutlined data-testid="failNotification" style={{ color: 'red' }} />,
        });
    }
}

export function createNotification(type: 'success' | 'fail' | 'warning', customMessage: string) {
    const setNotificationIcon = () => {
        if (type === 'fail') return <CloseCircleOutlined data-testid="failNotification" style={{ color: 'red' }} />;
        if (type === 'warning')
            return <ExclamationCircleOutlined data-testid="warningNotification" style={{ color: '#fa8c16' }} />;
        return <CheckCircleOutlined data-testid="successNotification" style={{ color: 'var(--green)' }} />;
    };

    notification.open({
        duration: 15,
        message: customMessage,
        icon: setNotificationIcon(),
    });
}
