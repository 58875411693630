import styled from 'styled-components';
import { Layout } from 'antd';

export const Dashboard = styled(Layout)`
    min-height: 100vh;

    .logo {
        height: 32px;
        margin: 16px;
    }

    .ant-layout-sider {
        background: var(--green);
    }
`;

export const Canvas = styled(Layout)`
    .site-layout-background {
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-layout-header {
        background: linear-gradient(90deg, var(--green), #01182b);
        height: 68px;
    }

    .trigger {
        padding: 15px 0;
        font-size: 25px;
        line-height: 43px;
        cursor: pointer;
        transition: color 0.3s;
        color: white;

        :hover {
            color: black;
        }

        @media (max-width: 990px) {
            padding: 15px;
        }
    }
`;
