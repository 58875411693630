import {
    CheckSquareOutlined,
    CheckOutlined,
    HourglassOutlined,
    AlertOutlined,
    LikeOutlined,
    UndoOutlined,
    RedoOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { TransactionType } from '../../types';

type StatusTagProps = {
    title: TransactionType;
};

const TransactionTypeTag: React.FC<StatusTagProps> = ({ title }) => {
    let color;
    let icon;
    switch (title) {
        case 'Full':
            color = 'green';
            icon = <CheckSquareOutlined />;
            break;
        case 'Trial':
            color = 'green';
            icon = <CheckOutlined />;
            break;
        case 'Chargeback':
            color = 'yellow';
            icon = <HourglassOutlined />;
            break;
        case 'Check Enrollment':
            color = 'red';
            icon = <AlertOutlined />;
            break;
        case 'Void':
            color = 'black';
            icon = <AlertOutlined />;
            break;
        case 'InstantUpgrade':
            color = 'green';
            icon = <LikeOutlined />;
            break;
        case 'Refund':
            color = 'orange';
            icon = <UndoOutlined />;
            break;
        case 'Rebill':
            color = 'orange';
            icon = <RedoOutlined />;
            break;
        default:
            color = 'grey';
            icon = null;
            break;
    }

    return (
        <Tag icon={icon} color={color} style={{ minWidth: '100px', textAlign: 'center' }}>
            {title || 'NO STATUS'}
        </Tag>
    );
};

export default TransactionTypeTag;
