import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CustomerState = { selectedCustomerId: number | null };

const initialState: CustomerState = {
    selectedCustomerId: null,
};

export const CustomersSlice = createSlice({
    name: 'Customer',
    initialState,
    reducers: {
        selectCustomer: (state, action: PayloadAction<number | null>) => {
            state.selectedCustomerId = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { selectCustomer } = CustomersSlice.actions;

export default CustomersSlice.reducer;
