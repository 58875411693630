import { Result, Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

type ErrorMessageProps = {
    returnToPageUrl?: string;
    message?: string;
    errorInfo?: string;
    callback?: () => void;
};

const ErrorAlertBox: React.FC<ErrorMessageProps> = ({
    returnToPageUrl,
    message = 'There seems to be an error',
    errorInfo,
    callback,
}) => {
    if (callback) {
        return (
            <Result
                status="warning"
                title={message}
                subTitle={errorInfo}
                extra={
                    <Button type="primary" key="console" onClick={() => callback()}>
                        Go back
                    </Button>
                }
            />
        );
    }

    if (!returnToPageUrl) {
        return <Result status="warning" title={message} subTitle={errorInfo} />;
    }

    return (
        <Result
            status="warning"
            title={message}
            subTitle={errorInfo}
            extra={
                <Link to={returnToPageUrl || '/'}>
                    <Button type="primary" key="console">
                        Go back
                    </Button>
                </Link>
            }
        />
    );
};

export default ErrorAlertBox;
