import { CheckSquareOutlined, HourglassOutlined, AlertOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { MemberStatus } from '../../types';

type MemberTagProps = {
    title: MemberStatus;
    withIcon?: boolean;
};

const MemberTag: React.FC<MemberTagProps> = ({ title, withIcon = false }) => {
    let color;
    let icon;
    switch (title) {
        case 'NEW':
            color = 'purple';
            icon = <CheckSquareOutlined />;
            break;
        case 'ACTIVE':
            color = 'green';
            icon = <CheckSquareOutlined />;
            break;
        case 'SUSPENDED':
            color = 'orange';
            icon = <HourglassOutlined />;
            break;
        case 'CANCELED':
            color = 'grey';
            icon = <AlertOutlined />;
            break;
        case 'DEACTIVATED':
            color = 'red';
            icon = <AlertOutlined />;
            break;
        default:
            color = 'grey';
            icon = null;
            break;
    }

    return (
        <Tag icon={withIcon && icon} color={color} style={{ minWidth: '60px', margin: '0.5rem', textAlign: 'center' }}>
            {title || 'NO STATUS'}
        </Tag>
    );
};

export default MemberTag;
