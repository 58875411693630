/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useUpdateEmailMutation } from '../services/customer';
import { fetchWithNotification } from '../utils/fetchWithNotification';

const useUpdateEmail = () => {
    const [updateEmail, { isLoading }] = useUpdateEmailMutation();

    async function updateEmailWithNotification(newEmail: string, id: number) {
        const result = await fetchWithNotification(() => updateEmail({ email: newEmail, customerId: id }), {
            success: 'Email address successfully updated!',
            fail: 'Email address failed to update. Reverting to previous',
        });
        return result;
    }

    return {
        updateEmail: updateEmailWithNotification,
        isLoading,
    };
};

export default useUpdateEmail;
