import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

type UsePaginationI = {
    currentPage: number;
    pageSize: number;
    changePageSize: (updatedPage: number, size: number) => void;
    changePage: (newPage: number, lastPage: number) => void;
    getTotalData: (totalElements: number) => void;
};

const usePagination = (initialPageSize: number): UsePaginationI => {
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [currentServerPage, setCurrentServerPage] = useState<number>(0); // ON THE SERVER, PAGES BEGIN AT 0
    const [useClientPagination, setUseClientPagination] = useState(false); // DONT USE SERVER SIDE PAGINATION
    const [searchParams] = useSearchParams();
    const pageParameter = searchParams.get('page');
    const sizeParameter = searchParams.get('size');
    const searchTermParamChecked = useRef(false);

    // FOR LOADING PAGE WITH QUERY PARAMS
    useEffect(() => {
        if (searchTermParamChecked.current) return;
        if (pageParameter && sizeParameter) {
            setCurrentServerPage(+pageParameter > 1 ? +pageParameter - 1 : 0);
            setPageSize(+sizeParameter);
        }
        searchTermParamChecked.current = true;
    }, [pageParameter, sizeParameter]);

    // GET THE TOTAL NUMBER OF RESULTS FROM SERVER RESPONSE
    function getTotalData(totalElements: number) {
        // THIS WILL GET ALL THE ORDERS FROM THE SERVER BY ASKING FOR 1 PAGE
        // WITH EITHER BE THE TOTAL ELEMENTS PROVIDED IN THE FIRST RESPONSE, OR A FIXED NUMBER
        setCurrentServerPage(0);
        setUseClientPagination(true);
        setPageSize(totalElements || 2000);
    }

    // HANDLES A NEW PAGE SELECTED
    // LAST PAGE MUST BE RETRIEVED
    function handlePageChange(newPage: number, lastPage: number) {
        // IF WE ARE USING CLIENTSIDE ANT D PAGINATION, IGNORE SERVER SIDE PAGINATION
        if (!useClientPagination && currentServerPage !== lastPage) {
            setCurrentServerPage(newPage > 1 ? newPage - 1 : 0);
        }
    }

    function handlePageSizeChange(updatedPage: number, size: number) {
        // IF WE ARE USING CLIENTSIDE ANT D PAGINATION, IGNORE SERVER SIDE PAGINATION
        if (!useClientPagination) {
            setPageSize(size);
            setCurrentServerPage(updatedPage > 1 ? updatedPage - 1 : 0);
        }
    }

    return {
        currentPage: currentServerPage,
        pageSize,
        changePageSize: handlePageSizeChange,
        changePage: handlePageChange,
        getTotalData,
    };
};

export default usePagination;
