import { Table, Typography } from 'antd';
import { UsageStats } from '../../types';
import SectionHeader from '../../components/ui/SectionHeader';
import { Section } from './customerDetail.styled';
import ExtraDetails from './ExtraDetails';

type UsageProps = {
    data?: UsageStats | UsageStats[];
};

const { Paragraph } = Typography;

const Usage: React.FC<UsageProps> = ({ data }) => {
    const isArray = Array.isArray(data);

    if (!data || (isArray && data.length < 1) || (!isArray && Object.keys(data).length < 1)) {
        return (
            <Section as="section">
                <SectionHeader title="Usage" />
                <Paragraph> No usage stats</Paragraph>
            </Section>
        );
    }

    const dataset = isArray ? data : [data];

    return (
        <Section as="section">
            <SectionHeader title="Usage" />
            <ExtraDetails text="View">
                <Table
                    dataSource={dataset}
                    locale={{ emptyText: 'No usage stats' }}
                    scroll={{ x: true }}
                    loading={false}
                    rowKey={() => `${Math.ceil(Math.random() * 50)}`}
                    pagination={false}
                >
                    <Table.Column
                        title="Bandwidth"
                        key="bandwidth"
                        align="center"
                        dataIndex="bandwidth"
                        render={(_, record: UsageStats) =>
                            (record.viewedTrialContentSize || 0) + (record.viewedTrialContentSize || 0)
                        }
                    />

                    <Table.Column align="center" key="ThumbsClicked" dataIndex="thumbClicks" title="Thumbs Clicked" />

                    <Table.Column
                        align="center"
                        key="viewedTrialVideoPage"
                        dataIndex="viewedTrialVideoPages"
                        title="Viewed Trial Videos"
                    />

                    <Table.Column
                        align="center"
                        key="viewedVideoPages"
                        dataIndex="viewedVideoPages"
                        title="Viewed Videos"
                    />

                    <Table.Column
                        align="center"
                        key="downloadedVideos"
                        dataIndex="downloadedVideos"
                        title="Video Downloads"
                    />

                    <Table.Column
                        align="center"
                        key="downloadedPhotosets"
                        dataIndex="downloadedPhotosets"
                        title="Photo Zip Download"
                    />
                </Table>
            </ExtraDetails>
        </Section>
    );
};

export default Usage;
