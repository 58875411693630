import { Menu, Layout } from 'antd';
import type { MenuProps } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import LogoPng from './logo.png';

const { Sider } = Layout;

type SideMenuProps = {
    open: boolean;
    closeMenu: () => void;
};

// const MenuLink = styled(Link)`
//     color: #1a90ff;
//     .activeState {
//         outline: red dashed 10px !important;
//     }
//     li .ant-menu-item-icon {
//         margin-left: 2rem;
//         :focus-visible {
//             outline: red dashed 10px !important;
//         }
//     }
// `;

const SidePanel = styled(Sider)`
    .ant-menu-item-selected {
        background-color: initial !important;
        color: white;
    }
    .activeState {
        outline: red dashed 10px !important;
        border: 2px solid blue;
    }
`;

const LogoArea = styled.div<{ menuOpen: boolean }>`
    background: transparent;
    transition: all 0.2s ease-out;
    padding: ${(props) => (props.menuOpen ? '1.4rem 5rem' : '2.1rem')};
    height: 10rem;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    img {
        width: 100%;
        max-width: 50px;
        overflow: hidden;
        height: auto;
        background: white;
        padding: 5px;
        border-radius: 4rem;
        transition: 0.4s ease-out;

        :hover {
            background: var(--dark);
            cursor: pointer;
        }
    }
`;

const items: MenuProps['items'] = [
    {
        label: <Link to="/">Customers</Link>,
        key: '1',
        icon: <SearchOutlined />,
    },
];

const SideMenu: React.FC<SideMenuProps> = ({ open, closeMenu }) => {
    const [belowBreakPoint, setBeloWBreakPoint] = useState(open);

    return (
        <SidePanel
            breakpoint="lg"
            width="250"
            collapsedWidth={belowBreakPoint ? 0 : undefined}
            trigger={null}
            collapsed={!open}
            onBreakpoint={(broken) => {
                if (belowBreakPoint !== broken) setBeloWBreakPoint(broken);
                if (broken) {
                    closeMenu();
                }
            }}
        >
            <LogoArea menuOpen={open}>
                <Link to="/">
                    <img src={LogoPng} alt="logo" />
                </Link>
            </LogoArea>
            <Menu theme="dark" mode="inline" items={items} />
        </SidePanel>
    );
};

export default SideMenu;
