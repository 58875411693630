import { Table, Typography } from 'antd';
import type { XSale } from '../../types';
import { getDateTimeString } from '../../utils/helpers';
import OrderTag from '../../components/ui/OrderTags';
import SectionHeader from '../../components/ui/SectionHeader';
import TableCell from '../../components/ui/TableCell';
import TransactionStatusTag from '../../components/ui/TransactionStatusTag';
import TransactionTypeTag from '../../components/ui/TransactionTypeTags';
import { Section } from './customerDetail.styled';

const { Paragraph } = Typography;

type LoginLogProps = {
    dataset: XSale[];
};

const XSales: React.FC<LoginLogProps> = ({ dataset }) => {
    if (dataset.length < 1) {
        return (
            <Section as="section">
                <SectionHeader title="XSales Subscriptions" />
                <Paragraph> No cross sales</Paragraph>
            </Section>
        );
    }

    return (
        <Section as="section">
            <div style={{ width: '100%' }}>
                <SectionHeader title="XSale Subscriptions" />
                <Table
                    dataSource={dataset}
                    locale={{ emptyText: 'No XSales' }}
                    scroll={{ x: true }}
                    rowKey="billerTransactionId"
                    loading={false}
                    pagination={false}
                >
                    <Table.Column
                        align="center"
                        title="Transaction ID"
                        key="xsale_transactionId"
                        render={(_, record: XSale) => (
                            <a
                                href={`${window.location.origin}/customers/${record.customerId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {record.billerTransactionId}
                            </a>
                        )}
                    />

                    <Table.Column
                        align="center"
                        title="Date"
                        key="xsale_date"
                        dataIndex="date"
                        render={(_, record: XSale) => getDateTimeString(record.transactionDate)}
                    />

                    <Table.Column
                        align="center"
                        title="Order status"
                        key="xsale_orderStatus"
                        render={(text, record: XSale) => (
                            <TableCell loading={false}>
                                {record.statuses.map((status, i) => (
                                    <OrderTag key={`${status}${i}`} title={status} />
                                ))}
                            </TableCell>
                        )}
                    />

                    <Table.Column
                        align="center"
                        title="Type"
                        key="xsale_type"
                        render={(text, record: XSale) => <TransactionTypeTag title={record.type} />}
                    />

                    <Table.Column
                        align="center"
                        title="Payment Status"
                        key="xsale_status"
                        render={(text, record: XSale) => <TransactionStatusTag status={record.paymentStatus} />}
                    />

                    <Table.Column
                        key="xsale_amount"
                        align="center"
                        title="Amount"
                        render={(text, record: XSale) => `$${record.amount}`}
                    />
                </Table>
            </div>
        </Section>
    );
};

export default XSales;
