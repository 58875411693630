import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from '../services/auth';
import { removeLocallyStoredValue } from '../utils/localStorage';
import { logout, applyLocallyStoredTokens, refreshTokens } from './actions';
import { AuthTokens } from '../services/auth/types';
import { storeAuthTokens } from '../features/auth/storeAuthTokens';

export type AuthRole = 'client' | 'admin' | null;

type AuthState = {
    accessToken: string | null;
    refreshToken: string | null;
};

const authSlice = createSlice({
    name: 'auth',
    initialState: { accessToken: null, refreshToken: null } as AuthState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(applyLocallyStoredTokens, (state, action: PayloadAction<Omit<AuthTokens, 'expires_in'>>) => {
            const { accessToken, refreshToken } = action.payload;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        });

        builder.addCase(logout, (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            // CLEAR TOKEN IN STORAGE
            removeLocallyStoredValue('crm_tkn_acrf');
        });

        builder.addCase(refreshTokens, (state, action: PayloadAction<AuthTokens>) => {
            const { accessToken, refreshToken, expires_in } = action.payload;
            storeAuthTokens(accessToken, refreshToken, expires_in);
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        });

        // USING AUTH SERVICE, SET THE TOKEN IN STATE
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
            const { accessToken, refreshToken, expires_in } = payload;
            storeAuthTokens(accessToken, refreshToken, expires_in);
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        });

        // USING THE GOOGLE AUTH SERVICE, SET THE TOKEN IN STATE
        builder.addMatcher(authApi.endpoints.googleLogin.matchFulfilled, (state, { payload }) => {
            const { accessToken, refreshToken, expires_in } = payload;
            storeAuthTokens(accessToken, refreshToken, expires_in);
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        });
    },
});

export default authSlice.reducer;
