import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetSearchSummaryQuery } from '../services/customer';
import { useSearchQuery } from '../services/search';
import { SearchResult, SearchSummary } from '../types';
import usePagination from './usePagination';

export type CustomerRow = SearchResult & SearchSummary & { children: SearchSummary[] };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCustomers = (initialPageSize: number) => {
    const [resultIds, setResultIds] = useState<number[]>([]);

    const [searchQuery, setSearchQuery] = useState('');

    const { pageSize, changePage, changePageSize, currentPage } = usePagination(initialPageSize);

    const {
        data: searchResults,
        error: searchResultsError,
        isError,
        isFetching: newSearchResultsLoading,
        isLoading: searchResultsLoading,
    } = useSearchQuery(
        {
            searchTerm: searchQuery,
            pageNumber: currentPage,
            pageSize,
        },
        { skip: searchQuery === '' }
    );
    const {
        data: searchSummaries,
        isLoading: summaryResultsLoading,
        isFetching: newSummaryResultsLoading,
    } = useGetSearchSummaryQuery(resultIds, { skip: resultIds.length < 1 });

    // IF WE HAVE SEARCH RESULTS, ADD THE IDS TO STATE TO BE USED IN FETCH SUMMARY REQUEST
    useEffect(() => {
        if (searchResults && searchResults.content.length > 0) {
            setResultIds(searchResults.content.map((result: SearchResult) => result.id));
        }
    }, [searchResults]);

    // MATCH THE SEARCH RESULTS WITH THE SUMMARY RESULT
    const findSummary = useCallback(
        (id: number) => {
            if (!searchSummaries) return undefined;
            return searchSummaries.find((summary) => summary.id === id);
        },
        [searchSummaries]
    );

    function createRowObject(result: SearchResult): CustomerRow {
        const summary = findSummary(result.id) as CustomerRow;
        if (summary) {
            const ob = { ...summary };
            if (summary.additionalMemberships.length > 0) ob.children = summary.additionalMemberships;
            return { ...result, ...ob } as CustomerRow;
        }
        return { ...result } as CustomerRow;
    }

    const memoCreateRowObject = useCallback(createRowObject, [findSummary]);

    function createDataset(): CustomerRow[] {
        if (!searchResults || searchResults.content.length < 1 || searchQuery === '') return [];
        return searchResults.content.map((result: SearchResult): CustomerRow => memoCreateRowObject(result));
    }

    const dataset = useMemo(createDataset, [searchResults, searchQuery, memoCreateRowObject]);

    const searchErrorMsg =
        (searchResultsError && 'data' in searchResultsError && searchResultsError.data?.error) || undefined;

    return {
        data: !searchQuery || searchResultsLoading ? [] : dataset,
        searchResultsLoading: searchResultsLoading || newSearchResultsLoading,
        searchSummaryLoading: summaryResultsLoading || newSummaryResultsLoading,
        isError: isError && searchQuery,
        errorMessage: searchErrorMsg,
        searchQuery,
        setSearchQuery,
        pageSize,
        changePage,
        changePageSize,
        currentPage,
        totalElements: (searchResults && searchResults.totalElements) || 0,
    };
};

export default useCustomers;
