import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { removeLocallyStoredValue, retrieveLocallyStoredValue } from '../utils/localStorage';
import { AuthTokens } from '../services/auth/types';
import { applyLocallyStoredTokens, logout } from '../store/actions';
import { withinExpirationDate } from '../utils/helpers';

type UseAuthentication = {
    isLoggedIn: boolean;
    signout: () => void;
};

const useAuth = (): UseAuthentication => {
    const dispatch = useDispatch();
    const tokensInState = useSelector((state: RootState) => state.auth.accessToken);
    const signout = () => dispatch(logout());

    function fetchStoredAuthentication(): UseAuthentication {
        const storedValues = retrieveLocallyStoredValue<AuthTokens>('crm_tkn_acrf');
        if (storedValues) {
            const { accessToken, refreshToken, expires_in } = storedValues;
            if (withinExpirationDate(expires_in)) {
                dispatch(applyLocallyStoredTokens({ accessToken, refreshToken }));
                return {
                    isLoggedIn: true,
                    signout,
                };
            }
            removeLocallyStoredValue('crm_tkn_acrf');
        }
        return {
            // IF NO VALID LOCALLY STORED TOKENS, USER IS UNAUTHENTICATED
            isLoggedIn: false,
            signout,
        };
    }

    if (!tokensInState) return fetchStoredAuthentication();

    return { isLoggedIn: true, signout };
};

export default useAuth;
