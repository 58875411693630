/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import 'antd/dist/antd.less';
import { useState } from 'react';
import { Layout } from 'antd';
import { Canvas, Dashboard } from './components/layout/Dashboard.styles';
import HeaderBar from './components/layout/TopMenu';
import SideMenu from './components/layout/SideMenu';
import Login from './containers/Login';
import useAuth from './hooks/useAuth';
import Breadcrumbs from './components/layout/BreadCrumbs';
import AppRoutes from './containers/AppRoutes';
import SessionTimeout from './features/sessionTimeout/SessionTimeout';
import { minutesToMilliseconds } from './utils/helpers';

const { Content, Footer } = Layout;
function App() {
    const [openMenu, setOpenMenu] = useState(false);

    const { isLoggedIn, signout } = useAuth();

    if (!isLoggedIn) return <Login />;

    return (
        <Dashboard>
            {process.env.NODE_ENV !== 'test' && (
                <SessionTimeout timeout={minutesToMilliseconds(60)} promptTimeout={30000} onIdle={signout} />
            )}
            <SideMenu open={openMenu} closeMenu={() => setOpenMenu(false)} />
            <Canvas>
                <HeaderBar open={openMenu} setOpenMenu={setOpenMenu} />
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumbs />
                    <AppRoutes />
                </Content>
                <Footer style={{ textAlign: 'center' }}>Customer Relations Management System</Footer>
            </Canvas>
        </Dashboard>
    );
}

export default App;
