import { Form, Input, Button, Checkbox, Space, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import type { CredentialResponse } from '@react-oauth/google';
import ErrorMessage from '../../components/ui/ErrorMessage';
import LogoPng from '../../components/layout/logo.png';

export type LoginFormSubmission = {
    userName: string;
    password: string;
};

export type LoginFormProps = {
    submit: (vals: LoginFormSubmission) => void;
    googleSuccess: (response: CredentialResponse) => void;
    googleError: () => void;
    errorMessage?: string;
    loading: boolean;
};

const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(var(--green) 20%, var(--dark) 90%);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LogoArea = styled.div`
    background: transparent;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    img {
        width: 100%;
        max-width: 55px;
        overflow: hidden;
        height: auto;
        background: white;
        background: transparent;
        padding: 5px;
        border-radius: 4rem;
    }
`;

const StyledLogin = styled.div`
    width: 100%;
    max-width: 450px;
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    margin: 1.5rem;
    margin-bottom: 2rem;
    position: relative;

    h1 {
        font-size: 2.5rem;
        text-align: center;
        font-weight: 900;
        color: #011529;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }
    .login-form-forgot {
        float: right;
    }
    .ant-col-rtl .login-form-forgot {
        float: left;
    }
    .login-form-button {
        width: 100%;
    }
`;

const Centered = styled.div`
    display: flex;
    justify-content: center;
`;

const LoginForm: React.FC<LoginFormProps> = ({ submit, googleSuccess, googleError, errorMessage, loading }) => {
    const [form] = Form.useForm();
    const inputNames = ['userName', 'password'];

    return (
        <Container>
            <StyledLogin>
                <LogoArea>
                    <img src={LogoPng} alt="logo" />
                </LogoArea>

                <Form
                    form={form}
                    name="login_form"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={(vals) => submit(vals)}
                >
                    <h1> CRM Login </h1>
                    <Form.Item
                        name={inputNames[0]}
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                    >
                        <Input
                            autoComplete="username"
                            name={inputNames[0]}
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Username"
                        />
                    </Form.Item>
                    <Form.Item
                        name={inputNames[1]}
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input
                            autoComplete="current-password"
                            name={inputNames[1]}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="/">
                            Forgot password
                        </a>
                    </Form.Item>

                    <Form.Item>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Button
                                loading={loading}
                                shape="round"
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                Sign in
                            </Button>
                            <Divider plain>OR</Divider>
                            <Centered>
                                {/* <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
                                    onSuccess={googleSuccess}
                                    onFailure={googleFailure}
                                    cookiePolicy="single_host_origin"
                                    buttonText="Sign in with Google"
                                    disabled={loading}
                                    prompt="select_account"
                                /> */}
                                <GoogleLogin
                                    onSuccess={(credentialResponse) => googleSuccess(credentialResponse)}
                                    onError={() => googleError()}
                                    auto_select
                                />
                            </Centered>
                        </Space>
                    </Form.Item>
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </Form>
            </StyledLogin>
        </Container>
    );
};

export default LoginForm;
