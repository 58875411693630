/* eslint-disable no-unused-vars */
import { Skeleton } from 'antd';
import React, { ReactElement } from 'react';

type LoadingSkeletonProps = {
    amount: number; // the number of loading skeletons to show
};

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ amount }) => {
    function createSkeletons(): ReactElement {
        const ar = new Array(amount);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return ar.fill(null).map((_, i) => <Skeleton key={i} active />) as unknown as ReactElement;
    }

    return createSkeletons();
};

export default LoadingSkeleton;
