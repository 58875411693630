import { Timeline } from 'antd';
import { LoginLog } from '../../../types';
import { getDateTimeString } from '../../../utils/helpers';

type LoginTimeLineProps = {
    logins: LoginLog[];
};

const LoginsTimeline: React.FC<LoginTimeLineProps> = ({ logins }) => (
    <Timeline mode="left">
        {logins.map((log: LoginLog, i: number) => {
            return (
                <Timeline.Item key={`${log.ip}${i}`} label={getDateTimeString(log.date)}>
                    <p>{log.device}</p>
                    <p>IP: {log.ip}</p>
                </Timeline.Item>
            );
        })}
    </Timeline>
);

export default LoginsTimeline;
