import { AuthTokens } from '../../services/auth/types';
import { calculateHoursUntilExpired, createUnixEpochElapsedExpireyTime } from '../../utils/helpers';
import { retrieveLocallyStoredValue, removeLocallyStoredValue, setLocallyStoredValue } from '../../utils/localStorage';

export function storeAuthTokens(token: string, refreshToken: string, expiresIn: number): void {
    if (retrieveLocallyStoredValue('crm_tkn_acrf')) removeLocallyStoredValue('crm_tkn_acrf');

    const expireyDate = createUnixEpochElapsedExpireyTime(calculateHoursUntilExpired(expiresIn));

    setLocallyStoredValue<AuthTokens>('crm_tkn_acrf', {
        accessToken: token,
        refreshToken,
        expires_in: expireyDate,
    });
}
