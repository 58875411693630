import { Button, Table, Typography } from 'antd';
import { LoginLog } from '../../types';
import { getDateTimeString } from '../../utils/helpers';
import HoverInfo from '../../components/ui/HoverInfo';
import SectionHeader from '../../components/ui/SectionHeader';
import { Section } from './customerDetail.styled';
import ExtraDetails from './ExtraDetails';

const { Paragraph } = Typography;

type LoginLogProps = {
    dataset: LoginLog[];
    showTimeline: () => void;
};

const LoginLogs: React.FC<LoginLogProps> = ({ dataset, showTimeline }) => {
    if (dataset.length < 1) {
        return (
            <Section as="section">
                <SectionHeader title="Login Logs" />
                <Paragraph> No successful logins</Paragraph>
            </Section>
        );
    }

    return (
        <Section as="section">
            <div style={{ width: '100%' }}>
                <SectionHeader title="Login Logs">
                    <Button onClick={showTimeline} type="primary">
                        View Timeline
                    </Button>
                </SectionHeader>
                <ExtraDetails text="View">
                    <Table
                        dataSource={dataset}
                        locale={{ emptyText: 'No successful logins' }}
                        scroll={{ x: true }}
                        rowKey="id"
                        loading={false}
                        pagination={false}
                    >
                        <Table.Column
                            title="Date"
                            key="date"
                            align="center"
                            dataIndex="date"
                            render={(_, record: LoginLog) => getDateTimeString(record.date)}
                        />

                        <Table.Column align="center" key="customerIp" dataIndex="ip" title="Customer IP" />

                        <Table.Column
                            key="userAgent"
                            dataIndex="userAgent"
                            align="center"
                            title="User Agent"
                            render={(_, record: LoginLog) =>
                                record.userAgent ? (
                                    <HoverInfo center vals={[record.userAgent || '']}>
                                        {record.userAgent.slice(0, 40)}...
                                    </HoverInfo>
                                ) : (
                                    ''
                                )
                            }
                        />

                        <Table.Column align="center" key="device" dataIndex="device" title="Device" />
                    </Table>
                </ExtraDetails>
            </div>
        </Section>
    );
};

export default LoginLogs;
