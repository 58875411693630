/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRefundTransactionsMutation } from '../services/customer';
import { createNotification } from '../utils/fetchWithNotification';
import useCreateNote from './useCreateNote';
import { TransactionRefundResponse } from '../types';

const useRefundTransactions = () => {
    const [refund, { isLoading, error }] = useRefundTransactionsMutation();
    const { createNote } = useCreateNote();

    const refundTransactions = async (ids: number[], customerId: string, agentNote?: string) => {
        if (!ids || ids.length < 1 || !customerId) return;
        const result = await refund({ transactions: ids, id: customerId });
        if (!result || (result && 'error' in result)) {
            const noteMessage =
                `Refund Error. Status ${'status' in result.error && result.error.status}` +
                ` Data: ${'data' in result.error && JSON.stringify(result.error.data)}`;

            createNotification('fail', 'Refund failed!');
            createNote('warning', noteMessage, +customerId, 'The failed refund has been recorded in customer notes');
        } else {
            createNotification('success', 'Refund Success!');
            if (agentNote) createNote('success', agentNote, +customerId, 'Successful refund');
        }

        return result;
    };

    const createRefundNote = async (
        transactions: TransactionRefundResponse[],
        customerId: string,
        agentNote?: string
    ) => {
        const note = agentNote || 'Refund was made'; // either agent note or general system note

        // 1. If each refund status is successful, show a single success notification indicating the number of transactions which were successfully refunded. EG All 3 refunds were successful
        const successfulRefund = transactions.filter((transaction) => transaction.statusCode === '200');
        if (successfulRefund.length === transactions.length) {
            createNote('success', note, +customerId, `All ${successfulRefund?.length} refunds were successful!`);
        } else {
            // 2. If one or more transactions fails, show a success notification for the ones that did succeed. Eg 2 out of 3 attempted refunds were successful
            await createNote(
                'warning',
                note,
                +customerId,
                `${successfulRefund.length} out of ${transactions.length} attempted refunds were successful!`
            );
            // 3. If one or more transactions fails, show a failure notification for each of the failed refunds, including the amount in the message. EG The attempted refund for $3.00 failed
            transactions.forEach(async (transaction) => {
                const noteType = transaction.statusCode === '-1' ? 'fail' : 'success';
                await createNote(
                    `${noteType}`,
                    note,
                    +customerId,
                    `Amount: ${transaction.amount}, Status: ${transaction.statusMessage}, ID: ${transaction.refundTransactionId}`
                );
            });
        }
    };

    const refundWithNotification = async (transactions: number[], customerId: string, agentNote?: string) => {
        const result = await refundTransactions(transactions, customerId);

        // if (result && !('error' in result)) {
        //     if (result.data) createRefundNote(result.data, customerId, agentNote);
        // }
    };

    return {
        refundTransactions,
        refund: refundTransactions,
        isRefunding: isLoading,
        refundError: error,
    };
};

export default useRefundTransactions;
